import { IComponentsRegistrar } from '@wix/thunderbolt-components-loader'
import { ILogger } from '@wix/thunderbolt-symbols'

type TBElementsCompLibrary = {
	registerComponents: Promise<IComponentsRegistrar['registerComponents']>
}

export const tbElementComponents = async (
	tbElementsPromise: Promise<TBElementsCompLibrary>,
	logger: ILogger
): Promise<IComponentsRegistrar> => {
	const tbElements = await tbElementsPromise
	const registerComponents = await tbElements.registerComponents
	logger.phaseEnded('component_loader')
	return {
		registerComponents,
	}
}
